import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { setupCalendar } from '@angelblanco/v-calendar';
import router from './router';
import i18n from './i18n';
import App from './App.vue';
import 'virtual:uno.css';

const pinia = createPinia();

// Languages supported by this SPA
const languages = ['en'];
// Languages that will redirect to the arabic SPA instead of the english
const arLanguages = ['ar'];
// Languages that have an rtl writing direction
// const rtlLanguages = ['ar']

router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  if (!to.params.lang || !languages.includes(to.params.lang)) {
    // const lang = window.navigator.language.slice(0, 2)
    // const param = (arLanguages.includes(lang))
    //   ? 'ar'
    //   : 'en'
    // const isRtl = rtlLanguages.includes(param)
    // if (isRtl) document.documentElement.dir = 'rtl'
    // else document.documentElement.dir = 'ltr'

    const param = 'en';

    i18n.locale = param;
    // sisStore.SET_LOCALE(param);

    next({ name: 'home', params: { lang: param } });
  } else if (from.params.lang !== to.params.lang) {
    const { lang } = to.params;
    document.documentElement.lang = lang;
    const isRtl = arLanguages.includes(lang);

    i18n.locale = lang;
    // sisStore.SET_LOCALE(lang);

    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }

    next();
  } else {
    next();
  }
});

const app = createApp(App);

app.use(setupCalendar, {});

app.use(pinia);
app.use(router);
app.use(i18n);

app.mount('#app');
