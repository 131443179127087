import { Route, RouteConfig, RouterView } from 'vue-router';
import {
  loginRoute,
  forgotPasswordRoute,
  profileRoute, publicTicketRoute,
} from '@base/router/routes';

const routes: RouteConfig[] = [
  loginRoute,
  forgotPasswordRoute,
  profileRoute,
  publicTicketRoute,
  {
    path: '/:lang',
    name: 'lang',
    component: RouterView,
    redirect: (to: Route) => ({ name: 'home', lang: to.params.lang }),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/JWL_Home.vue'),
      },
      {
        path: 'not-authorised',
        name: 'notAuthorised',
        component: () => import('@base/components/JWL_NotAuthorised.vue'),
      },
      {
        path: 'student',
        name: 'student',
        component: RouterView,
        redirect: (to: Route) => ({ name: 'studentList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'studentList',
            component: () => import('@/views/JWL_Student.vue'),
          },
          {
            path: 'new',
            name: 'studentNew',
            component: () => import('@/views/student/JWL_StudentNew.vue'),
          },
          {
            path: ':student/overview',
            name: 'studentOverview',
            component: () => import('@/views/student/JWL_StudentOverview.vue'),
          },
          {
            path: ':student/edit',
            name: 'studentEdit',
            component: () => import('@/views/student/JWL_StudentEdit.vue'),
          },
        ],
      },
      {
        path: 'facilitator',
        name: 'facilitator',
        component: RouterView,
        redirect: (to: Route) => ({ name: 'facilitatorList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'facilitatorList',
            component: () => import('@/views/JWL_Facilitator.vue'),
          },
          {
            path: 'new',
            name: 'facilitatorNew',
            component: () => import('@/views/facilitator/JWL_FacilitatorNew.vue'),
          },
          {
            path: ':facilitator/edit',
            name: 'facilitatorEdit',
            component: () => import('@/views/facilitator/JWL_FacilitatorEdit.vue'),
          },
        ],
      },
      {
        path: 'coordinator',
        name: 'coordinator',
        component: RouterView,
        redirect: (to: Route) => ({ name: 'coordinatorList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'coordinatorList',
            component: () => import('@/views/JWL_Coordinator.vue'),
          },
          {
            path: 'new',
            name: 'coordinatorNew',
            component: () => import('@/views/coordinator/JWL_CoordinatorNew.vue'),
          },
          {
            path: ':coordinator/edit',
            name: 'coordinatorEdit',
            component: () => import('@/views/coordinator/JWL_CoordinatorEdit.vue'),
          },
        ],
      },
      {
        path: 'clc-partner',
        name: 'clcPartner',
        component: RouterView,
        redirect: (to: Route) => ({ name: 'clcPartnerList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'clcPartnerList',
            component: () => import('@/views/JWL_Clc.vue'),
          },
          {
            path: 'new',
            name: 'clcPartnerNew',
            component: () => import('@/views/clc/JWL_ClcPartnerEdit.vue'),
          },
          {
            path: ':clc/edit',
            name: 'clcPartnerEdit',
            component: () => import('@/views/clc/JWL_ClcPartnerEdit.vue'),
          },
        ],
      },
      {
        path: 'programme',
        name: 'programme',
        component: RouterView,
        redirect: (to) => {
          if (useUserAuthorisation().isAuthorised(useUserAuthorisation().rolesAndRightsList.RIGHT__PROGRAMME_PROFESSIONAL__DISPLAY, false)) {
            return { name: 'programmeProfessionalList', lang: to.params.lang };
          }
          if (useUserAuthorisation().isAuthorised(useUserAuthorisation().rolesAndRightsList.RIGHT__PROGRAMME_ACADEMIC__DISPLAY, false)) {
            return { name: 'bachelorCohortList', lang: to.params.lang };
          }
          if (useUserAuthorisation().isAuthorised(useUserAuthorisation().rolesAndRightsList.RIGHT__PROGRAMME_GEL__DISPLAY, false)) {
            return { name: 'gel', lang: to.params.lang };
          }
          return { name: 'notAuthorised' };
        },
        children: [
          {
            path: 'list',
            name: 'programmeList',
            component: () => import('@/views/JWL_Programme.vue'),
            redirect: (to) => ({ name: 'programmeProfessionalList', lang: to.params.lang }),
            children: [
              {
                path: 'professional-certificate',
                name: 'programmeProfessionalList',
                component: () => import('@/views/programme/SIS_ProgrammeList.vue'),
              },
              {
                path: 'bachelor',
                name: 'programmeBachelorList',
                component: () => import('@/views/bachelor/SIS_BachelorList.vue'),
              },
            ],
          },
          {
            path: 'gel',
            name: 'gel',
            component: () => import('@/views/gel/index.vue'),
            children: [
              {
                path: 'data-handling',
                name: 'gelDataHandling',
                component: () => import('@/views/gel/DataHandling.vue'),
              },
              {
                path: 'unhandled-applications/:status',
                name: 'gelUnhandledApplications',
                component: () => import('@/views/gel/UnhandledApplications.vue'),
              },
              {
                path: 'term-management',
                name: 'gelTermManagement',
                component: () => import('@/views/gel/TermManagement.vue'),
                redirect: (to: Route): any => {
                  const now = new Date();
                  return {
                    name: 'gelTermYear',
                    params: {
                      lang: to.params.lang,
                      termYear: now.getFullYear().toString(),
                    },
                  };
                },
                children: [
                  {
                    path: ':termYear',
                    name: 'gelTermYear',
                    component: () => import('@/views/gel/TermManagement.vue'),
                  },
                ],
              },
              {
                path: 'student-terms',
                name: 'gelStudentManagement',
                component: () => import('@/views/gel/students/index.vue'),
                redirect: (to: Route): any => {
                  const now = new Date();
                  return {
                    name: 'gelStudentManagementTerms',
                    params: {
                      lang: to.params.lang,
                      termYear: now.getFullYear().toString(),
                    },
                  };
                },
                children: [
                  {
                    path: ':termYear',
                    name: 'gelStudentManagementTerms',
                    component: () => import('@/views/gel/students/StudentTermManagement.vue'),
                    /* redirect: (to: Route): any => {
                      const now = new Date();
                      return {
                        name: 'gelStudentManagementTermByCode',
                        params: {
                          lang: to.params.lang,
                          termYear: now.getFullYear().toString(),
                        },
                      };
                    }, */
                    children: [
                      {
                        path: ':term',
                        name: 'gelStudentManagementTermById',
                        component: () => import('@/views/gel/students/StudentTermManagement.vue'),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'classroom/:termId/:courseId',
                name: 'gelClassroomManagement',
                component: () => import('@/views/gel/classroom/index.vue'),
              },
              {
                path: 'add-applicant',
                name: 'gelAddApplicant',
                component: () => import('@/views/gel/RegisterForm.vue'),
              },
              {
                path: 'edit-applicant/:studentId/:status',
                name: 'gelEditApplicant',
                component: () => import('@/views/gel/RegisterForm.vue'),
              },
              {
                path: 'certificates',
                name: 'gelCertificateExport',
                component: () => import('@/views/gel/CertificateExport.vue'),
              },
              {
                path: 'applicant-export',
                name: 'gelApplicantExport',
                component: () => import('@/views/gel/ApplicantExport.vue'),
              },
            ],
          },
          {
            path: 'professional-certificate',
            name: 'programmeProfessionalRoot',
            component: RouterView,
            children: [
              {
                path: 'new',
                name: 'programmeNew',
                component: () => import('@/views/programme/JWL_ProgrammeEdit.vue'),
              },
              {
                path: ':programme/overview',
                name: 'programmeOverview',
                component: () => import('@/views/programme/JWL_ProgrammeOverview.vue'),
              },
              {
                path: ':programme/edit',
                name: 'programmeEdit',
                component: () => import('@/views/programme/JWL_ProgrammeEdit.vue'),
              },
              {
                path: ':programme/classes',
                name: 'programmeClasses',
                component: () => import('@/views/programme/JWL_ProgrammeClasses.vue'),
              },
              {
                path: ':programme/finish',
                name: 'programmeFinish',
                component: () => import('@/views/programme/JWL_ProgrammeFinish.vue'),
              },
              {
                path: ':programme/certificate-export',
                name: 'programmeCertificateExport',
                component: () => import('@/views/programme/JWL_ProgrammeCertificateExport.vue'),
              },
              {
                path: ':programme/dropout-flagging',
                name: 'programmeDropoutFlagging',
                component: () => import('@/views/programme/dropout-flagging/index.vue'),
              },
            ],
          },
          {
            path: 'bachelor/:programme',
            name: 'programmeBachelorRoot',
            component: () => import('@/views/bachelor/index.vue'),
            redirect: (to) => ({ name: 'bachelorCohortList', lang: to.params.lang }),
            children: [
              {
                path: 'cohorts',
                name: 'bachelorCohort',
                component: RouterView,
                redirect: (to) => ({ name: 'bachelorCohortList', lang: to.params.lang }),
                children: [
                  {
                    path: 'list',
                    name: 'bachelorCohortList',
                    component: () => import('@/views/bachelor/cohort/index.vue'),
                  },
                  {
                    path: 'import/:cohortId',
                    name: 'bachelorCohortStudentImport',
                    component: () => import('@/views/bachelor/cohort/StudentImport.vue'),
                  },
                  {
                    path: 'new',
                    name: 'bachelorCohortNew',
                    component: () => import('@/views/bachelor/cohort/New.vue'),
                  },
                ],
              },
              {
                path: 'terms',
                name: 'bachelorTermManagement',
                component: () => import('@/views/bachelor/term/index.vue'),
                redirect: (to: Route): any => {
                  const now = new Date();
                  return {
                    name: 'bachelorTermYear',
                    params: {
                      lang: to.params.lang,
                      programme: to.params.programme,
                      termYear: now.getFullYear().toString(),
                    },
                  };
                },
                children: [
                  {
                    path: ':termYear',
                    name: 'bachelorTermYear',
                    component: () => import('@/views/bachelor/term/Year.vue'),
                  },
                  {
                    path: ':termYear/create-internship',
                    name: 'bachelorTermInternshipNew',
                    component: () => import('@/views/bachelor/term/InternshipNew.vue'),
                  },
                ],
              },
              {
                path: 'students',
                name: 'bachelorStudentManagement',
                component: () => import('@/views/bachelor/student-management/index.vue'),
                redirect: (to: Route): any => {
                  const now = new Date();
                  return {
                    name: 'bachelorStudentManagementYear',
                    params: {
                      lang: to.params.lang,
                      programme: to.params.programme,
                      termYear: now.getFullYear().toString(),
                    },
                  };
                },
                children: [
                  {
                    path: ':termYear',
                    name: 'bachelorStudentManagementYear',
                    component: () => import('@/views/bachelor/student-management/Year.vue'),
                    children: [
                      {
                        path: 'term/:termKey',
                        name: 'bachelorStudentTermCourse',
                        beforeEnter: (to, from, next) => {
                          if (from.name !== 'termClassroom' && from.name !== null) {
                            localStorage.removeItem('currentCourseIndex');
                          }
                          next();
                        },
                        component: () => import('@/views/bachelor/student-management/StudentTermCourse.vue'),
                      },
                      {
                        path: 'internship',
                        name: 'bachelorStudentInternship',
                        component: () => import('@/views/bachelor/student-management/StudentInternship.vue'),
                        children: [
                          {
                            path: 'term/:termKey',
                            name: 'bachelorStudentInternshipTerm',
                            component: () => import('@/views/bachelor/student-management/StudentTermCourse.vue'),
                          },
                        ],
                      },
                      {
                        path: 'past-classrooms',
                        name: 'bachelorStudentPastClassrooms',
                        component: () => import('@/views/bachelor/student-management/PastClassroomTabs.vue'),
                        redirect: (to: Route) => ({
                          name: 'bachelorStudentPastClassroomsOpen',
                          lang: to.params.lang,
                        }),
                        children: [
                          {
                            path: 'open',
                            name: 'bachelorStudentPastClassroomsOpen',
                            component: () => import('@/views/bachelor/student-management/OpenClassrooms.vue'),
                          },
                          {
                            path: 'closed',
                            name: 'bachelorStudentPastClassroomsClosed',
                            component: () => import('@/views/bachelor/student-management/ClosedClassrooms.vue'),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'classroom/:termKey/:courseId/:currentCourseIndex',
                name: 'termClassroom',
                component: () => import('@/views/bachelor/classroom/index.vue'),
              },
              {
                path: 'transfer-in',
                name: 'bachelorTransferIn',
                component: () => import('@/views/bachelor/TransferIn.vue'),
                children: [
                  {
                    path: 'internal',
                    name: 'bachelorTransferInInternal',
                    component: () => import('@/views/bachelor/transfer/InternalIn.vue'),
                  },
                  {
                    path: 'external',
                    name: 'bachelorTransferInExternal',
                    component: () => import('@/views/bachelor/transfer/ExternalIn.vue'),
                  },
                  {
                    path: 'approval',
                    name: 'bachelorTransferInApproval',
                    component: () => import('@/views/bachelor/transfer/ApprovalIn.vue'),
                  },
                ],
              },
              {
                path: 'transfer-out',
                name: 'bachelorTransferOut',
                component: () => import('@/views/bachelor/transfer/index.vue'),
                children: [
                  {
                    path: 'grades',
                    name: 'bachelorTransferOutGrades',
                    component: () => import('@/views/bachelor/transfer/GradesOut.vue'),
                  },
                  {
                    path: 'bachelor',
                    name: 'bachelorTransferOutBachelor',
                    component: () => import('@/views/bachelor/transfer/Out.vue'),
                  },
                ],
              },
              {
                path: 'verification',
                name: 'bachelorVerification',
                component: () => import('@/views/bachelor/verification/index.vue'),
              },
              {
                path: 'dropout-flagging',
                name: 'bachelorDropoutFlagging',
                component: () => import('@/views/bachelor/dropout-flagging/index.vue'),
                redirect: (to: Route): any => {
                  const now = new Date();
                  return {
                    name: 'bachelorDropoutFlaggingYear',
                    params: {
                      lang: to.params.lang,
                      programme: to.params.programme,
                      termYear: now.getFullYear().toString(),
                    },
                  };
                },
                children: [
                  {
                    path: ':termYear',
                    name: 'bachelorDropoutFlaggingYear',
                    component: () => import('@/views/bachelor/dropout-flagging/Year.vue'),
                    children: [
                      {
                        path: 'term/:termKey',
                        name: 'bachelorDropoutFlaggingTerm',
                        component: () => import('@/views/bachelor/dropout-flagging/Term.vue'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'dropout-flagging/:programme/:termKey/create',
            name: 'dropoutFlaggingCreate',
            component: () => import('@/views/dropout-flagging/Create.vue'),
          },
          {
            path: 'dropout-flagging/:programme/:termKey/:itemId',
            name: 'dropoutFlaggingEdit',
            component: () => import('@/views/dropout-flagging/Create.vue'),
          },
        ],
      },
      {
        path: 'user',
        name: 'user',
        component: RouterView,
        redirect: (to: Route) => ({ name: 'userList', lang: to.params.lang }),
        children: [
          {
            path: 'list',
            name: 'userList',
            component: () => import('@/views/JWL_User.vue'),
          },
          {
            path: 'new',
            name: 'userNew',
            component: () => import('@/views/user/JWL_UserNew.vue'),
          },
          {
            path: ':user/edit',
            name: 'userEdit',
            component: () => import('@/views/user/JWL_UserEdit.vue'),
          },
        ],
      },
      {
        path: 'reporting',
        name: 'reporting',
        component: () => import('@/views/JWL_Reporting.vue'),
      },
      {
        path: 'dataCleanup',
        name: 'dataCleanup',
        component: () => import('@/views/data_cleanup/JWL_DataCleanup.vue'),
      },
      {
        path: 'userMerge',
        name: 'userMerger',
        component: () => import('@/views/data_cleanup/JWL_UserMerger.vue'),
      },
      {
        path: 'userMerge/:deleteUserJwlId/:mergeUserJwlId',
        name: 'userMergerParam',
        component: () => import('@/views/data_cleanup/JWL_UserMerger.vue'),
      },
      {
        path: 'userDataChecker',
        name: 'userDataChecker',
        component: () => import('@/views/data_cleanup/JWL_UserDataChecker.vue'),
        children: [
          {
            path: 'userDataCheckerDuplicateEmails',
            name: 'userDataCheckerDuplicateEmails',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerDuplicateEmail.vue'),
          },
          {
            path: 'userDataCheckerDataWithSpaces',
            name: 'userDataCheckerDataWithSpaces',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerDataWithSpaces.vue'),
          },
          {
            path: 'userDataCheckerPhoneNumber',
            name: 'userDataCheckerPhoneNumber',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerPhoneNumber.vue'),
          },
          {
            path: 'userDataCheckerUserMandatoryFields',
            name: 'userDataCheckerUserMandatoryFields',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerUserMandatoryFields.vue'),
          },
          {
            path: 'userDataCheckerValidEmail',
            name: 'userDataCheckerValidEmail',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerValidEmail.vue'),
          },
          {
            path: 'userDataCheckerUserMandatoryName',
            name: 'userDataCheckerUserMandatoryName',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerUserMandatoryName.vue'),
          },
          {
            path: 'userDataCheckerPossibleDataCleansing',
            name: 'userDataCheckerPossibleDataCleansing',
            component: () => import('@/views/data_cleanup/JWL_UserDataCheckerPossibleDataCleansing.vue'),
          },
        ],
      },
      {
        path: 'authorisation',
        name: 'authorisation',
        component: () => import('@/views/authorisation/JWL_Authorisation.vue'),
      },
      {
        path: 'authorisationRoleList',
        name: 'authorisationRoleList',
        component: () => import('@/views/authorisation/JWL_Authorisation_Role.vue'),
      },
      {
        path: 'authorisationRightList',
        name: 'authorisationRightList',
        component: () => import('@/views/authorisation/JWL_Authorisation_Right.vue'),
      },
    ],
  },
];

export default routes;
