import { createWebHistory, createRouter } from 'vue-router';
import routes from './_routes';
import i18n from '../i18n';

const router = createRouter({
  history: createWebHistory(),
  // base: import.meta.env.VITE_BASE_URL,
  routes,
});

const languages = ['en', 'ar'];
const arLanguages = ['ar'];
const rtlLanguages = ['ar'];

router.beforeEach((to, from, next) => {
  if (!to.params.lang || !languages.includes(to.params.lang)) {
    const lang = window.navigator.language.slice(0, 2);
    const isRtl = rtlLanguages.includes(lang);
    const param = (arLanguages.includes(lang))
      ? 'ar'
      : 'en';
    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }
    i18n.locale = param;

    next({ name: 'home', params: { lang: param } });
  } else if (from.params.lang !== to.params.lang) {
    const { lang } = to.params;
    document.documentElement.lang = lang;
    const isRtl = arLanguages.includes(lang);

    i18n.locale = lang;

    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }

    next();
  } else {
    next();
  }
});

export default router;
