import { createI18n } from 'vue-i18n';

import countries from '@base/../node_modules/i18n-iso-countries/langs/en.json';

import commonEn from '@base/locales/en.json';
import commonEs from '@base/locales/es.json';
import en from './locales/en.json';

export const defaultLocale = 'en';

export const languages = {
  en,
};

export const commonLanguages = {
  en: commonEn,
  es: commonEs,
};

function loadLocaleMessages () {
  const locales = [{ en: languages.en }];
  const commonLocales = [{ en: commonLanguages.en }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });

  commonLocales.forEach((lang) => {
    const key = Object.keys(lang);
    Object.assign(messages[key], lang[key]);
  });

  messages.en = Object.assign(messages.en, countries);

  return messages;
}

export default createI18n({
  legacy: false,
  locale: import.meta.env.VITE_VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VITE_VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
