<template>
  <div>
    <JwlHeader :with-language-switcher="false" v-if="showHeader" />
    <div class="container" v-if="error">
      <JwlError :jwl-error="{ trans: true, message: 'network.error.serverError', type: 'studyBreak' }"></JwlError>
    </div>
    <RouterView v-if="showView" />
    <JwlFooter />
  </div>
</template>

<script>
  import { REDIRECT_URL, ALLOWED_PATHS } from '@base/store/UserAuthorisation';

  export default defineComponent({
    name: 'App',
    setup () {
      let currentUser = null;
      const error = ref(false);

      const router = useRouter();
      const route = useRoute();

      const moduleStore = useModuleStore();

      const showHeader = computed(() => !!route
          && !!route.name
          && !ALLOWED_PATHS.includes(route.name)
          && !!moduleStore.currentUser);

      const showView = computed(() => !!moduleStore.currentUser
        || (route.name && ALLOWED_PATHS.includes(route.name)));

      onMounted(() => {
        moduleStore.SET_SYSTEM_MODE('sis');
        moduleStore.getBaseData()
          .then(() => {
            currentUser = moduleStore.currentUser;
          })
          .catch((e) => {
            if (e.message === 'network.error.serverError') {
              error.value = true;
            }
            if (route.name && !ALLOWED_PATHS.includes(route.name)) {
              localStorage.setItem(REDIRECT_URL, route.fullPath);
              router.push({
                name: 'login',
              });
            }
          });
      });

      return {
        currentUser,
        showHeader,
        showView,
        error,
      };
    },
  });
</script>

<style lang="scss">
@import "@base/styles/jwlApp";
@import "styles/sis";

header.jwl-header {
  margin-bottom: 0;
}

h1 {
  font-size: 3.25rem;
}
</style>
