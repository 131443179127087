import { UserAuthorisation } from '@base/store/UserAuthorisation';
import useModuleStore from '@base/store/moduleStore';
import { IS_ROLE_AND_RIGHT } from '@base/store/JwlGlobals';
import router from '@/router';

export const useUserAuthorisation = () => {
  const rolesAndRightsList = UserAuthorisation;

  const { currentUser } = useModuleStore();

  const isAuthorised = (rightId, redirect = true) => {
    // For the migration phase
    if (!IS_ROLE_AND_RIGHT) {
      return true;
    }

    if (currentUser?.applicationRights.includes(rightId)) {
      // console.log(`userAuthorisationPlugin.js: rightId=${rightId} is in rights of ${currentUser.id}`);
      return true;
    }
    if (currentUser) {
      // console.log(`userAuthorisationPlugin.js: rightId=${rightId} is NOT in rights of ${currentUser?.id}`);
      if (redirect) {
        router.push({ name: 'notAuthorised', missingRight: rightId }).catch();
      }
    }
    return false;
  };

  const hasRole = (roleId, redirect = true) => {
    // For the migration phase
    if (!IS_ROLE_AND_RIGHT) {
      return true;
    }

    if (currentUser?.applicationRoles.includes(roleId)) {
      // console.log(`userAuthorisationPlugin.js: rightId=${rightId} is in rights of ${currentUser.id}`);
      return true;
    }
    if (currentUser) {
      // console.log(`userAuthorisationPlugin.js: rightId=${rightId} is NOT in rights of ${currentUser?.id}`);
      if (redirect) {
        router.push({ name: 'notAuthorised', missingRight: roleId }).catch();
      }
    }
    return false;
  };

  const isResponsible = (taskResponsibility) => {
    if (!taskResponsibility) {
      return false;
    }
    const allowedOnlineTasks = isAuthorised(rolesAndRightsList.RIGHT__COURSE_TASK_ONLINE__DISPLAY, false);
    const allowedOnsiteTasks = isAuthorised(rolesAndRightsList.RIGHT__COURSE_TASK_ONSITE__DISPLAY, false);
    const isOnlineAndResponsible = allowedOnlineTasks && ['online_facilitator', 'online-onsite_facilitator'].includes(taskResponsibility);
    const isOnsiteAndResponsible = allowedOnsiteTasks && ['onsite_facilitator', 'onsite-online_facilitator'].includes(taskResponsibility);
    return (isOnlineAndResponsible || isOnsiteAndResponsible) && !isAuthorised(rolesAndRightsList.RIGHT__SUBMISSION__CREATE, false);
  };

  return {
    rolesAndRightsList,
    isAuthorised,
    hasRole,
    isResponsible,
  };
};

export default useUserAuthorisation;
